import { Link } from 'gatsby';
import React, { FC, ReactElement } from 'react';

import './Footer.scss';

const Footer: FC = (): ReactElement => (
  <footer>
    <div className="main-wrapper">
      <img
        className="logoImg"
        src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo_strapline.svg"
        alt="motokiki"
      />
      <div className="list-cont">
        <a href="https://www.facebook.com/Motokikiuk/" target="_blank" rel="noreferrer">
          <img
            className="icons"
            width="45"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-fb-blue.svg"
            alt="facebook"
          />
        </a>
        <a href="https://twitter.com/Motokikiuk" target="_blank" rel="noreferrer">
          <img
            className="icons"
            width="45"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-tw-blue.svg"
            alt="twitter"
          />
        </a>
        <a href="https://www.instagram.com/motokikiuk/" target="_blank" rel="noreferrer">
          <img
            className="icons"
            width="45"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-insta-blue.svg"
            alt="instagram"
          />
        </a>
        <a href="https://www.linkedin.com/company/motokiki" target="_blank" rel="noreferrer">
          <img
            className="icons"
            width="45"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-li-blue.svg"
            alt="linkedin"
          />
        </a>
      </div>
      <nav className="nav-cont">
        <ul className="ul-cont">
          <li>
            <Link className="li-a" to="/cookiePolicy">
              Cookie policy
            </Link>
          </li>
          <li>
            <Link className="li-a" to="/privacy">
              Privacy policy
            </Link>
          </li>
          <li>
            <Link className="li-a" to="/terms">
              Terms &amp; Conditions
            </Link>
          </li>
        </ul>
      </nav>
      <img
        className="tyreSafeImg"
        width="200"
        height="auto"
        src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/16976-assets_logo-tyreSafe.png"
        alt="TyreSafe"
      />
    </div>
  </footer>
);

export default Footer;
