import { Link } from 'gatsby';
import React, { FC, ReactElement, useState } from 'react';

import './Header.scss';

const Header: FC = (): ReactElement => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <div>
      <header className=" header">
        <button
          className={`burger ${!menuIsOpen ? `` : `menuIsOpen`}`}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          type="button"
        >
          <span />
        </button>
        <Link to="/">
          <img
            className=" s-mxAuto header-logo"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo_strapline.svg"
            alt="Motokiki"
          />
        </Link>
        <nav className={!menuIsOpen ? `modalHide` : ``}>
          <ul>
            <li>
              <Link activeClassName="active" to="/tyres">
                Tyres
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/blogs">
                Articles
              </Link>
            </li>
            {/* <li>
              <Link activeClassName="active" to="/partner">
                Car products
              </Link> 
            </li> */}
            <li>
              <Link activeClassName="active" to="/FAQ">
                FAQ
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
