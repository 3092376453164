import React, { FC, useEffect } from 'react';

import Footer from '../Footer';
import Header from '../Header';

const Layout: FC = ({ children }) => {
  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement(`script`);
      script.setAttribute(`src`, src);
      script.addEventListener(`load`, callback);
      document.getElementsByTagName(`script`)[0].insertBefore(script, null);
    };
    loadScript(
      `https://www.motoreasy.com/js/dist/widget-bootstrap-0.0.4.min.js`,
      () => {
        // After script loaded...
        dispatchEvent(new Event(`load`));
      },
    );
  }, []);

  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
