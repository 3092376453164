import Privacy from '@/templates/Privacy';
import React, { FC } from 'react';

import Layout from '../components/Layout/Layout';

const PrivacyPolicy: FC = () => (
  <div>
    <Layout>
      <Privacy />
    </Layout>
  </div>
);

export default PrivacyPolicy;
