import FooterOptions from '@/components/FooterOptions';
import React, { FC, ReactElement } from 'react';

import './Privacy.scss';

const Privacy: FC = (): ReactElement => (
  <>
    <FooterOptions
      className="privacy footerOptions"
      Header="The Motokiki Privacy Policy"
      SubHeader="Thank you for displaying interest in our company and our website."
      SubHeader2="We at Motokiki take the protection of your personal data and privacy very seriously."
    />

    <div className="privacyPageContainer">
      <h2>Personal Data</h2>
      <p>
        We have a designated data protection controller and any further
        enquiries should be sent to
        <a href="mailto:dpo@motokiki.com">dpo@motokiki.com</a>. We want
        transparency so you are fully aware of the collection and use of
        personal data on our website.
      </p>
      <p>
        We constantly strive to safeguard the privacy of all Motokiki’s website
        visitors and service users.
      </p>
      <p>
        We use cookies on our website. Cookies are not always necessary for the
        provision of
        <a href="/" rel="noopener">
          Motokiki.com
        </a>
        however we will ask you to consent to our use of cookies when you first
        visit our website.
      </p>
      <h3>How we use your personal data</h3>
      <p>
        At Motokiki we may process account data such as name, email address,
        date of birth, address and vehicle registration number for use of our
        service. We also may process other usage data that may include your IP
        address, location, browser type and version, operating system, referral
        source, length of visit, page views and website navigation paths, as
        well as information about the timing, frequency and pattern of your
        service use.
      </p>
      <p>
        We will process this data for purpose of analysing the use of the
        Motokiki website to improve our services for website users. We will also
        be using Google analytics for this purpose.
      </p>
      <p>
        We may process usage and account data for the purposes of operating
        <a href="https://www.motokiki.com/">Motokiki.com</a> to aid in improving
        our services, ensuring the security of our website and services,
        maintaining back-ups of our databases and for communicating with you.
      </p>
      <p>
        In addition, we may also process any of the aforementioned data where
        such processing is necessary for compliance with a legal obligation to
        which we are subject.
      </p>
      <h3>Providing your personal data to others</h3>
      <p>
        In order that all the information and details that you are given when
        you use our website is accurate, helpful and meets your requirements, it
        is necessary for us to share some or all of your personal data with our
        commercial partners who supply the goods and services that you access
        through this website.
      </p>
      <p>
        When those commercial partners use your data to generate quotes and
        confirm their services, they use your personal data. This means that for
        those purposes those commercial partners are in charge of how they
        handle your data and we are not responsible for this.
      </p>
      <h3>Retaining and deleting personal data</h3>
      <p>
        We comply with all legal obligations in relation to the retention and
        deletion of personal data.
      </p>
      <p>
        Personal data that we process for any purpose shall not be kept for
        longer than is necessary for that specific purpose.
      </p>
      <p>
        In some cases, it is not possible for us to specify in advance the
        periods for which your personal data will be retained.
      </p>
      <h3>Amendments</h3>
      <p>
        We may update this policy from time to time by publishing a new version
        on our website.
      </p>
      <p>
        We may not notify you of any amends to this policy but updates will
        always be displayed on this website.
      </p>
      <div>
        <h2>Your rights</h2>
        <p>
          We have summarised your rights under data protection law, however, we
          recommend you read the relevant laws and guidance from the regulatory
          authorities for a full explanation.
        </p>
        <p>Your principal rights under the data protection law are:</p>
        <ul>
          <li>the right to access;</li>
          <li>the right to rectification;</li>
          <li>the right to erasure;</li>
          <li>the right to restrict processing;</li>
          <li>the right to object to processing;</li>
          <li>the right to data portability;</li>
          <li>the right to complain to a supervisory authority; and</li>
          <li>the right to withdraw consent.</li>
        </ul>
        <p>
          You have the right to our confirmation as to whether or not we process
          your personal data, where we do, access to the personal data, together
          with certain additional information that includes details of the
          purposes of the processing, the categories of personal data concerned
          and the recipients of the personal data. Providing the rights and
          freedoms of others are not affected, we will supply to you a copy of
          your personal data which is subject to a reasonable fee. More
          information can be obtained via our data controller officer (
          <a href="mailto:dpo@motokiki.com"> dpo@motokiki.com </a>)
        </p>
        <p>
          You have the right to have any inaccurate personal data about you
          rectified and, taking into account the purposes of the processing, to
          have any incomplete personal data about you completed.
        </p>
        <p>
          You will also be able to opt out of data collection should you wish to
          do so. This can also be done by emailing
          <a href="mailto:dpo@motokiki.com">dpo@motokiki.com </a>
        </p>
      </div>
      <div>
        <h2>About cookies</h2>
        <p>
          A cookie is a file containing an identifier that is sent by a web
          server to a web browser and is stored by the browser. The identifier
          is then sent back to the server each time the browser requests a page
          from the server.
        </p>
        <p>
          Cookies may be either "persistent" cookies or "session" cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed.
        </p>
        <p>
          Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about you
          may be linked to the information stored in and obtained from cookies.
        </p>
        <h3>Cookies that we use</h3>
        <p>
          For more information on cookies we use please click
          <a href="https://www.motokiki.com/cookies">here</a>
        </p>
        <h3>Cookies used by our service providers</h3>
        <p>
          We use Google Analytics to analyse the use of our website. Google
          Analytics gathers information about website use by means of cookies.
          The information gathered relating to our website is used to create
          reports about the use of our website. Google's privacy policy is
          available at:
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          .
        </p>
      </div>
    </div>
  </>
);
export default Privacy;
