import React, { ReactElement } from 'react';

import './FooterOptions.scss';

interface Props {
  Header: string;
  SubHeader: string;
  SubHeader2?: string;
  className?: string;
}

const FooterOptions = (props: Props): ReactElement => {
  const { Header, SubHeader, SubHeader2, className } = props;
  return (
    <section className={className}>
      <h1>{Header}</h1>
      <h4 className="hidden">{SubHeader}</h4>
      <h4 className="hidden">{SubHeader2}</h4>
    </section>
  );
};

FooterOptions.defaultProps = {
  SubHeader2: ``,
  className: ``,
};

export default FooterOptions;
